import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import Section from '~components/Section'
import { motion, AnimatePresence } from 'framer-motion'

const Team = ({ className, title, leadership }) => {

	const [active, setActive] = useState(0)

	return (
		<Wrap className={className}>
			<ImageColumn>
				<Title className='h2-serif'>{title}</Title>
				<StickyImageContainer>
					<StickyImageInner>
						{leadership.map((member, index) => (
							<StickyImage 
								key={member?._key} 
								image={member?.image}
								active={active === index}
							/>
						))}
					</StickyImageInner>
				</StickyImageContainer>
			</ImageColumn>
			<List>
				{leadership.map((member, index) => (
					<Member 
						key={member.key} 
						onMouseEnter={() => setActive(index)}
						onMouseLeave={() => setActive(undefined)}
					>
						<MemberImage image={member.image}/>
						<MemberName className='h2-sans'>{member.name} <Hyphen>—</Hyphen></MemberName>
						<MemberRole className='h2-sans'>{member.role}</MemberRole>
					</Member>
				))}	
			</List>
		</Wrap>
	)
}
const Wrap = styled(Section)`
	padding-top: var(--s);
	margin-bottom: var(--l);
	${mobile}{
		padding-top: var(--m-m);
		margin-bottom: var(--m-l);
	}
`
const List = styled.div`
	grid-column: 5/17;
	${tablet}{
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: 25px;
	}
	${mobile}{
		grid-column: span 16;
		grid-template-columns: repeat(16, 1fr);
		grid-column-gap: 20px;
	}
`
const ImageColumn = styled.div`
	grid-column: span 4;
	position: relative;
	${mobile}{
		grid-column: span 16;
		margin-bottom: var(--m-s);
	}
`
const Title = styled.h2`
`
const StickyImageContainer = styled.div`
	position: sticky;
	top: 0;
	padding-top: 40px;
	padding-right: 20%;
	${tablet}{
		display: none;
	}
`
const StickyImageInner = styled.div`
	position: relative;
	padding-bottom: 100%;
`
const StickyImage = styled(Image)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: ${props => props.active ? 1 : 0};
	transition: opacity 0.3s ease;
`
const Member = styled.div`
	grid-column: span 12;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: top;
	padding: 18px 0px 13px;
	border-bottom: 1px solid var(--black);
	grid-column-gap: 25px;
	${tablet}{
		grid-column: span 16;
		grid-column-gap: 20px;
		padding: 12px 0px 8px;
	}
	${mobile}{
		grid-template-columns: repeat(1, 1fr);
		&:last-of-type{
			border-bottom: none;
		}
	}
`
const MemberImage = styled(Image)`
	display: none;
	${tablet}{
		display: block;
		margin-bottom: 40px;
	}
	${mobile}{
		margin-bottom: 28px;
	}
`
const MemberName = styled.div`
`
const Hyphen = styled.div`
	display: none;
	/* ${tablet}{
		display: inline-block;
	} */
`
const MemberRole = styled.div`
`

Team.propTypes = {
	className: PropTypes.string,
	leadership: PropTypes.array,
	title: PropTypes.string
}

export default Team